import React, { Component } from 'react';
import { Form, FormField, Header, Button, Accordion, Icon, Modal, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import './PatientDetail.scss';
import ControlPane from '../../../../UI/Controls/Control/ControlPane';
import CustomTextArea from '../../../../UI/CustomTextArea/CustomTextArea';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { TITLE_OPTIONS, ORGANISATION } from '../../../../assets/constants';
import EnoteAxios from '../../../../axios';
import { FormatDate, GetPatientTabID, GetInjuryTabID, GetTreatmentTabID, GetPatientFullNameShort, IsValidDate } from '../../../../assets/helper';
import CustomLoader from '../../../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../../../UI/ConditionalArea/ConditionalArea';
import CustomReactTable, { XOverflowMode } from '../../../../UI/CustomReactTable/CustomReactTable';
import { NotificationManager } from 'react-notifications';
import FormHeader from '../../../../UI/FormHeader/FormHeader';
import printJS from 'print-js';
import FocusLock from 'react-focus-lock';
import { createColumnHelper } from '@tanstack/table-core';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const TITLE = 'title';
const GENDER = 'gender';
const DOB = 'dob';
const ETHNICITY_ID = 'ethnicityID';
const FIRST_VISIT_DATE = 'firstVisitDate';
const OCCUPATION = 'occupation';
const PHYSICAL_ADDRESS = 'physicalAddress';
const EMAIL = 'email';
const HOME_PHONE = 'homePhone';
const WORK_PHONE = 'workPhone';
const MOBILE_PHONE = 'mobilePhone';
const GP_NAME = 'gpName';
const REFERRER = 'referrer';
const SMOKING = 'smoking';
const VEGETARIAN = 'vegetarian';
const VEGAN = 'vegan';
const MEDICAL_HISTORY = 'medicalHistory';
const SURGICAL_HISTORY = 'surgicalHistory';
const FAMILY_HISTORY = 'familyHistory';
const SOCIAL_HISTORY = 'socialHistory';
const GYNAECOLOGICAL_HISTORY = 'gynaecologicalHistory';
const INVESTIGATIONS = 'investigations';
const RECREATIONAL_DRUGS = 'recreationalDrugs';
const NHI = 'nhi';
const NEXT_OF_KIN = 'nextOfKin';
const NEXT_OF_KIN_PHONE = 'nextOfKinPhone';
const ALCOHOL = 'alcohol';

const GENDER_OPTIONS = [
    { text: 'Male', value: 'M' },
    { text: 'Female', value: 'F' }
];

const ETHNICITY_OPTIONS = [
    { text: "Not Stated", value: 0 },
    { text: "Chinese", value: 1 },
    { text: "South Korean", value: 2 },
    { text: "Japanese", value: 3 },
    { text: "South East Asian", value: 4 },
    { text: "Other Asian", value: 5 },
    { text: "NZ European", value: 6 },
    { text: "Other European", value: 7 },
    { text: "Fijian", value: 8 },
    { text: "Indian", value: 9 },
    { text: "Samoan", value: 10 },
    { text: "Tongan", value: 11 },
    { text: "Niuean", value: 12 },
    { text: "Tokelauan", value: 13 },
    { text: "Other Pacific", value: 14 },
    { text: "Cook Island Maori", value: 15 },
    { text: "NZ Maori", value: 16 }
]

const columnHelper = createColumnHelper();

class PatientDetail extends Component {
    state = {
        patient: {
            [FIRST_NAME]: '',
            [LAST_NAME]: '',
            [TITLE]: '',
            [GENDER]: '',
            [DOB]: '',
            [ETHNICITY_ID]: 0,
            [FIRST_VISIT_DATE]: '',
            [OCCUPATION]: '',
            [PHYSICAL_ADDRESS]: '',
            [EMAIL]: '',
            [HOME_PHONE]: '',
            [WORK_PHONE]: '',
            [MOBILE_PHONE]: '',
            [GP_NAME]: '',
            [REFERRER]: '',
            [SMOKING]: false,
            [VEGETARIAN]: false,
            [VEGAN]: false,
            [MEDICAL_HISTORY]: '',
            [SURGICAL_HISTORY]: '',
            [FAMILY_HISTORY]: '',
            [SOCIAL_HISTORY]: '',
            [GYNAECOLOGICAL_HISTORY]: '',
            [INVESTIGATIONS]: '',
            [RECREATIONAL_DRUGS]: '',
            [NHI]: '',
            [NEXT_OF_KIN]: '',
            [NEXT_OF_KIN_PHONE]: '',
            [ALCOHOL]: false
        },
        injuryList: [],
        loading: true,
        createMode: true,
        showAdditionalFields: false,
        exportLink: '',
        showConfirmation: false,
        duplicatePatientList: []
    }

    exportLinkRef = React.createRef();

    injuryColumns = [
        columnHelper.accessor(row => FormatDate(row.date), {
            id: 'date',
            header: 'Date of Injury',
        }),
        columnHelper.accessor(row => row.accNumber, {
            id: 'accNumber',
            header: 'ACC Number',
        }),
        columnHelper.accessor(row => row.readCodes.join(', '), {
            id: 'readCodes',
            header: 'Read Code',
        }),
        columnHelper.accessor(row => {
            if (this.props.user.organisationId === ORGANISATION.NZRA) {
                return row.reason;
            } else if (this.props.user.organisationId === ORGANISATION.NZASA) {
                return row.accidentAsa;
            }
        }, {
            id: 'detail',
            header: 'Detail',
            cell: r => (
                <span className='no-wrap'>
                    {r.renderValue()}
                </span>
            )
        }),
        columnHelper.accessor(row => row, {
            id: 'action',
            header: 'Action',
            cell: r => (
                <span className='add'>
                    <Icon title='Create New Treatment' onClick={(e) => this.addTreatment(e, r.getValue())} name='add' />
                </span>
            )
        }),
    ];

    async componentDidMount() {
        if (this.props.data && this.props.data.patientId) {
            // TODO Load Patient Info and injury records
            await EnoteAxios.get('/api/patient/getPatient', {
                params: {
                    patientId: this.props.data.patientId
                }
            }).then(response => {
                const { patientId, createdUserId, ...patientDetail } = response.data;
                this.setState({
                    patient: {
                        ...patientDetail,
                        [DOB]: FormatDate(patientDetail.dob),
                        [FIRST_VISIT_DATE]: FormatDate(patientDetail.firstVisitDate)
                    },
                    createMode: false
                });
            }).catch(err => {
                console.log(err);
            });

            await this.LoadInjuryList();
        } else {
            // Creating a new patient
        }
        this.setState({
            loading: false
        });
    }

    LoadInjuryList = async () => {
        await EnoteAxios.get('/api/injury/GetInjuryListForPatient', {
            params: {
                patientId: this.props.data.patientId
            }
        }).then(response => {
            this.setState({
                injuryList: response.data
            });
        }).catch(err => {
            console.log(err);
        });
    }

    async componentDidUpdate(prevProps) {
        if (this.props && prevProps !== this.props) {
            if (this.props.reloadTimestamp > prevProps.reloadTimestamp) {
                await this.LoadInjuryList();
            }
        }
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            patient: {
                ...prevState.patient,
                [name]: value
            }
        }));

        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    checkBoxChangeHandler = (e, { name, checked }) => {
        this.setState({
            patient: {
                ...this.state.patient,
                [name]: checked
            }
        });

        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    dateChangeHandler = (selectedDate, name) => {
        this.setState(prevState => ({
            patient: {
                ...prevState.patient,
                [name]: FormatDate(selectedDate)
            }
        }));

        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    getInjuryTitle = (injury) => {
        return GetPatientFullNameShort(this.state.patient.firstName, this.state.patient.lastName) + ' Injury: ' + FormatDate(injury.date);
    }

    savePatientHandler = async (bypassDuplicateCheck = false) => {
        if (!this.isFormValid(this.state.patient)) {
            return;
        }

        this.toggleLoaddingState(true);
        const patientDTO = {
            ...this.state.patient,
            patientId: this.props.data.patientId,
            createdUserId: this.props.user.userId
        };

        await EnoteAxios.post('/api/patient/savePatient', patientDTO, {
            params: {
                bypassDuplicateCheck: bypassDuplicateCheck
            }
        }).then(response => {
            if (response.data && response.data.responseData) {
                const data = {
                    patientId: response.data.responseData
                }
                this.setState({
                    loading: false,
                    showConfirmation: false
                });
                this.props.onTabMetadataChange(this.props.tabID, GetPatientTabID(data.patientId), GetPatientFullNameShort(this.state.patient.firstName, this.state.patient.lastName), GetPatientTabID(data), data);
            }
        }).catch(err => {
            console.log(err);
            this.toggleLoaddingState(false);
            if (err && err.response && err.response.data && err.response.data.responseData) {
                this.setState({
                    showConfirmation: true,
                    duplicatePatientList: err.response.data.responseData
                })
            }
        })
    }

    exportPatientDetail = async (print) => {
        NotificationManager.info('File will be exported shortly...', 'Preparing document', 3000);
        await EnoteAxios.get('/api/report/ExportPatientDetail', {
            params: {
                patientId: this.props.data.patientId
            },
            responseType: 'blob'
        }).then(response => {
            let blob = new Blob([response.data], { type: 'application/octet-stream' });
            let link = window.URL.createObjectURL(blob);
            if (!print) {
                this.setState({
                    exportLink: link,
                }, () => {
                    NotificationManager.success('File exported successfully', 'Success', 3000, null, true);
                    this.exportLinkRef.current.click();
                });
            } else {
                printJS(link);
            }
        }).catch(err => {
            console.log(err);
            NotificationManager.error('Failed to export file', 'Failed');
        });
    }

    isFormValid = (patient) => {
        let errorMessage = '';
        let isValid = true;
        if (!patient.firstName || !patient.lastName) {
            errorMessage += 'Patient name is required.';
        }

        if (!IsValidDate(patient.dob, true)) {
            errorMessage += '\nInvalid Date of Birth';
        }

        if (!IsValidDate(patient.firstVisitDate, true)) {
            errorMessage += '\nInvalid First Visit Date';
        }

        if (errorMessage.length > 0) {
            NotificationManager.error(errorMessage, 'Failed');
            isValid = false;
        }

        return isValid;
    }

    onRowClick = (e, rowInfo) => {
        const injury = rowInfo.original;
        this.props.onTabAdd(GetInjuryTabID(this.props.data.patientId, injury.injuryId), this.getInjuryTitle(injury), 'InjuryDetail', {
            injuryId: injury.injuryId,
            patient: {
                patientId: this.props.data.patientId,
                firstName: this.state.patient.firstName,
                lastName: this.state.patient.lastName,
                dob: this.state.patient.dob
            }
        });
    }

    toggleLoaddingState = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    addTreatment = (e, injury) => {
        e.stopPropagation();
        this.props.onTabAdd(GetTreatmentTabID(this.props.data.patientId, injury.injuryId, 0), 'New Treatment: ' + GetPatientFullNameShort(this.state.patient.firstName, this.state.patient.lastName), 'TreatmentDetail', {
            injuryId: injury.injuryId,
            treatmentId: 0,
            treatmentIndex: 0,
            injuryDate: injury.date,
            patient: {
                patientId: this.props.data.patientId,
                firstName: this.state.patient.firstName,
                lastName: this.state.patient.lastName,
                dob: this.state.patient.dob,
                nhi: this.state.patient.nhi
            }
        })
    }

    render() {
        let content = null;
        if (this.props.user.organisationId === ORGANISATION.NZRA) {
            content = (
                <React.Fragment>
                    <Form.Group widths='equal'>
                        <FormField>
                            <Form.Dropdown label='Title' options={TITLE_OPTIONS} selection name={TITLE} value={this.state.patient.title} onChange={this.inputChangeHandler} placeholder='Select Title' />
                        </FormField>
                        <FormField>
                            <Form.Input required label='First Name' type='text' name={FIRST_NAME} value={this.state.patient.firstName} onChange={this.inputChangeHandler} placeholder='First Name' />
                        </FormField>
                        <FormField>
                            <Form.Input required label='Last Name' type='text' name={LAST_NAME} value={this.state.patient.lastName} onChange={this.inputChangeHandler} placeholder='Last Name' />
                        </FormField>
                        <FormField>
                            <Form.Dropdown label='Gender' options={GENDER_OPTIONS} selection value={this.state.patient.gender} name={GENDER} onChange={this.inputChangeHandler} placeholder='Select Gender' />
                        </FormField>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <FormField>
                            <Form.Dropdown label='Ethnicity' options={ETHNICITY_OPTIONS} selection name={ETHNICITY_ID} value={this.state.patient.ethnicityID} onChange={this.inputChangeHandler} placeholder='Select Ethnicity' />
                        </FormField>
                        <FormField>
                            <Form.Input label='Occupation' type='text' name={OCCUPATION} value={this.state.patient.occupation} onChange={this.inputChangeHandler} placeholder='Occupation' />
                        </FormField>
                        <FormField>
                            <DateInput
                                label='Date of Birth'
                                dateFormat='YYYY-MM-DD'
                                name={DOB}
                                startMode='year'
                                placeholder='YYYY-MM-DD'
                                initialDate='1990-01-01'
                                value={this.state.patient.dob}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                hideMobileKeyboard
                                animation='none'
                                error={!IsValidDate(this.state.patient.dob, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                        <FormField>
                            <DateInput
                                label='First Visit Date'
                                dateFormat='YYYY-MM-DD'
                                name={FIRST_VISIT_DATE}
                                placeholder='YYYY-MM-DD'
                                value={this.state.patient.firstVisitDate}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                hideMobileKeyboard
                                animation='none'
                                error={!IsValidDate(this.state.patient.firstVisitDate, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input width='4' label='NHI' type='text' name={NHI} value={this.state.patient.nhi} onChange={this.inputChangeHandler} placeholder='NHI' />
                    </Form.Group>

                    <Header dividing>Contact</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input label='Email' type='email' name={EMAIL} value={this.state.patient.email} onChange={this.inputChangeHandler} placeholder='Email' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Mobile Phone' type='text' name={MOBILE_PHONE} value={this.state.patient.mobilePhone} onChange={this.inputChangeHandler} placeholder='Mobile Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Home Phone' type='text' name={HOME_PHONE} value={this.state.patient.homePhone} onChange={this.inputChangeHandler} placeholder='Home Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Work Phone' type='text' name={WORK_PHONE} value={this.state.patient.workPhone} onChange={this.inputChangeHandler} placeholder='Work Phone' />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <Form.Field width='8'>
                            <label>Address</label>
                            <CustomTextArea rows='4' name={PHYSICAL_ADDRESS} value={this.state.patient.physicalAddress} onChange={this.inputChangeHandler} placeholder='Address' />
                        </Form.Field>
                        <Form.Field width='4'>
                            <Form.Input label='GP Name' type='text' name={GP_NAME} value={this.state.patient.gpName} onChange={this.inputChangeHandler} placeholder='GP Name' />
                        </Form.Field>
                        <Form.Field width='4'>
                            <Form.Input label='Referrer' type='text' name={REFERRER} value={this.state.patient.referrer} onChange={this.inputChangeHandler} placeholder='Referrer Name' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <FormField width='2'>
                            <Form.Checkbox label='Smoking' name={SMOKING} checked={this.state.patient.smoking} onChange={this.checkBoxChangeHandler} />
                        </FormField>
                        <FormField width='2'>
                            <Form.Checkbox label='Vegetarian' name={VEGETARIAN} checked={this.state.patient.vegetarian} onChange={this.checkBoxChangeHandler} />
                        </FormField>
                        <FormField width='2'>
                            <Form.Checkbox label='Vegan' name={VEGAN} checked={this.state.patient.vegan} onChange={this.checkBoxChangeHandler} />
                        </FormField>
                    </Form.Group>
                </React.Fragment>
            );
        } else if (this.props.user.organisationId === ORGANISATION.NZASA) {
            content = (
                <React.Fragment>
                    <Form.Group widths='equal'>
                        <FormField>
                            <Form.Dropdown label='Title' options={TITLE_OPTIONS} selection name={TITLE} value={this.state.patient.title} onChange={this.inputChangeHandler} placeholder='Select Title' />
                        </FormField>
                        <FormField>
                            <Form.Input required label='First Name' type='text' name={FIRST_NAME} value={this.state.patient.firstName} onChange={this.inputChangeHandler} placeholder='First Name' />
                        </FormField>
                        <FormField>
                            <Form.Input required label='Last Name' type='text' name={LAST_NAME} value={this.state.patient.lastName} onChange={this.inputChangeHandler} placeholder='Last Name' />
                        </FormField>
                        <FormField>
                            <Form.Dropdown label='Gender' options={GENDER_OPTIONS} selection value={this.state.patient.gender} name={GENDER} onChange={this.inputChangeHandler} placeholder='Select Gender' />
                        </FormField>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <FormField>
                            <Form.Dropdown label='Ethnicity' options={ETHNICITY_OPTIONS} selection name={ETHNICITY_ID} value={this.state.patient.ethnicityID} onChange={this.inputChangeHandler} placeholder='Select Ethnicity' />
                        </FormField>
                        <FormField>
                            <Form.Input label='Occupation' type='text' name={OCCUPATION} value={this.state.patient.occupation} onChange={this.inputChangeHandler} placeholder='Occupation' />
                        </FormField>
                        <FormField>
                            <DateInput
                                label='Date of Birth'
                                dateFormat='YYYY-MM-DD'
                                name={DOB}
                                startMode='year'
                                placeholder='YYYY-MM-DD'
                                initialDate='1990-01-01'
                                value={this.state.patient.dob}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                hideMobileKeyboard
                                animation='none'
                                error={!IsValidDate(this.state.patient.dob, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                        <FormField>
                            <DateInput
                                label='First Visit Date'
                                dateFormat='YYYY-MM-DD'
                                name={FIRST_VISIT_DATE}
                                placeholder='YYYY-MM-DD'
                                value={this.state.patient.firstVisitDate}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                hideMobileKeyboard
                                animation='none'
                                error={!IsValidDate(this.state.patient.firstVisitDate, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input width='4' label='NHI' type='text' name={NHI} value={this.state.patient.nhi} onChange={this.inputChangeHandler} placeholder='NHI' />
                    </Form.Group>

                    <Header dividing>Contact</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input label='Email' type='text' name={EMAIL} value={this.state.patient.email} onChange={this.inputChangeHandler} placeholder='Email' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Mobile Phone' type='text' name={MOBILE_PHONE} value={this.state.patient.mobilePhone} onChange={this.inputChangeHandler} placeholder='Mobile Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Home Phone' type='text' name={HOME_PHONE} value={this.state.patient.homePhone} onChange={this.inputChangeHandler} placeholder='Home Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Work Phone' type='text' name={WORK_PHONE} value={this.state.patient.workPhone} onChange={this.inputChangeHandler} placeholder='Work Phone' />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <Form.Field width='8'>
                            <label>Address</label>
                            <CustomTextArea rows='4' name={PHYSICAL_ADDRESS} value={this.state.patient.physicalAddress} onChange={this.inputChangeHandler} placeholder='Address' />
                        </Form.Field>
                        <Form.Input width='4' label='Next of Kin' type='text' name={NEXT_OF_KIN} value={this.state.patient.nextOfKin} onChange={this.inputChangeHandler} placeholder='Next of Kin' />
                        <Form.Input width='4' label='Next of Kin Phone' type='text' name={NEXT_OF_KIN_PHONE} value={this.state.patient.nextOfKinPhone} onChange={this.inputChangeHandler} placeholder='Next of Kin Phone' />
                    </Form.Group>
                    <Accordion>
                        <Accordion.Title className='additional-info-title' as={Header} dividing={this.state.showAdditionalFields} active={this.state.showAdditionalFields} onClick={() => this.setState(prevState => ({ showAdditionalFields: !prevState.showAdditionalFields }))}>
                            <Icon name='dropdown' /> Additional Information
                        </Accordion.Title>
                        <Accordion.Content active={this.state.showAdditionalFields}>
                            <React.Fragment>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Medical History</label>
                                        <CustomTextArea rows='2' name={MEDICAL_HISTORY} value={this.state.patient.medicalHistory} onChange={this.inputChangeHandler} placeholder='Medical History' />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Surgical History</label>
                                        <CustomTextArea rows='2' name={SURGICAL_HISTORY} value={this.state.patient.surgicalHistory} onChange={this.inputChangeHandler} placeholder='Surgical History' />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Family History</label>
                                        <CustomTextArea rows='2' name={FAMILY_HISTORY} value={this.state.patient.familyHistory} onChange={this.inputChangeHandler} placeholder='Family History' />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Social History</label>
                                        <CustomTextArea rows='2' name={SOCIAL_HISTORY} value={this.state.patient.socialHistory} onChange={this.inputChangeHandler} placeholder='Social History' />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Gynaecological History</label>
                                        <CustomTextArea rows='2' name={GYNAECOLOGICAL_HISTORY} value={this.state.patient.gynaecologicalHistory} onChange={this.inputChangeHandler} placeholder='Gynaecological History' />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Investigations</label>
                                        <CustomTextArea rows='2' name={INVESTIGATIONS} value={this.state.patient.investigations} onChange={this.inputChangeHandler} placeholder='Investigations' />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input width='8' label='Recreational Drugs' type='text' name={RECREATIONAL_DRUGS} value={this.state.patient.recreationalDrugs} onChange={this.inputChangeHandler} placeholder='Recreational Drugs' />
                                </Form.Group>
                                <Form.Group widths='8'>
                                    <FormField width='2'>
                                        <Form.Checkbox label='Smoking' name={SMOKING} checked={this.state.patient.smoking} onChange={this.checkBoxChangeHandler} />
                                    </FormField>
                                    <FormField width='2'>
                                        <Form.Checkbox label='Alcohol' name={ALCOHOL} checked={this.state.patient.alcohol} onChange={this.checkBoxChangeHandler} />
                                    </FormField>
                                </Form.Group>
                            </React.Fragment>
                        </Accordion.Content>
                    </Accordion>
                </React.Fragment>
            );
        }

        return (
            <div className='PatientDetail'>
                {
                    this.props.inModal ?
                        <CustomLoader active={this.state.loading} size='massive' cssLoader /> :
                        <CustomLoader active={this.state.loading} size='massive' />
                }
                <ConditionalArea show={!this.state.loading}>
                    <ControlPane>
                        <Button primary name='savePatient' onClick={() => this.savePatientHandler(false)} disabled={!this.props.touched}>Save</Button>
                        <Button primary
                            name='createInjury'
                            onClick={() => this.props.onTabAdd(GetInjuryTabID(0), 'New Injury: ' + GetPatientFullNameShort(this.state.patient.firstName, this.state.patient.lastName), 'InjuryDetail', {
                                patient: {
                                    patientId: this.props.data.patientId,
                                    firstName: this.state.patient.firstName,
                                    lastName: this.state.patient.lastName,
                                    dob: this.state.patient.dob,
                                    nhi: this.state.patient.nhi
                                }
                            })}
                            disabled={this.state.createMode}>
                            New Injury
                        </Button>
                        {/*<OptionsDropdown>
                            <DropdownItem title='Save patient notes as PDF' onClick={() => this.exportPatientDetail(false)} disabled={this.state.createMode}>Export</DropdownItem>
                            <DropdownItem title='Print patient and injury information' onClick={() => this.exportPatientDetail(true)} disabled={this.state.createMode}>Print</DropdownItem>
                        </OptionsDropdown>*/}
                    </ControlPane>
                    <FormHeader dividing trimtop>Patient Detail</FormHeader>
                    <Form autoComplete="off">
                        {content}
                    </Form>
                    {
                        this.props.data.patientId && (
                            <div className='InjuryHistoryDiv'>
                                <Header as='h3' dividing>Injury History</Header>
                                <CustomReactTable
                                    columns={this.injuryColumns}
                                    data={this.state.injuryList}
                                    onRowClick={this.onRowClick}
                                    noDataText='No injury found'
                                    clickable
                                    fixedStyle={false}
                                    xOverflowMode={XOverflowMode.Scroll}
                                />
                            </div>
                        )
                    }
                    <ControlPane>
                        <Button primary name='savePatient' onClick={() => this.savePatientHandler(false)} disabled={!this.props.touched}>Save</Button>
                    </ControlPane>
                </ConditionalArea>
                <a className='HiddenLink' target='_blank' rel="noopener noreferrer" href={this.state.exportLink} download={this.state.patient.firstName + ' ' + this.state.patient.lastName + '.pdf'} ref={this.exportLinkRef}>export</a>
                <Modal
                    open={this.state.showConfirmation}
                    closeIcon
                    onClose={() => this.setState({ showConfirmation: false })}
                >
                    <Modal.Content>
                        <p>The following patient(s) already exist. Do you still want to create this patient anayway?</p>
                        <List bulleted>
                            {
                                this.state.duplicatePatientList.map(p => {
                                    return <List.Item key={p.patientId}>{p.firstName} {p.lastName} - {FormatDate(p.dob)}</List.Item>
                                })
                            }
                        </List>
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button onClick={() => this.setState({ showConfirmation: false })}>Close</Button>
                            <Button primary onClick={() => this.savePatientHandler(true)}>Save</Button>
                        </FocusLock>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    }
}

export default connect(mapStateToProps)(PatientDetail);